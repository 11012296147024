import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import {NetworkAuth} from "../helper/network/auth/NetworkAuth";

Vue.use(VueRouter)

/**
 * check if token is available and not expired
 * @returns {boolean|{name: string}}
 */
async function auth() {
    const token = localStorage.getItem("access_token")
    const expiresAt = parseInt(store.state.auth.expiresAt)
    const hasToken = token !== null
    const isExpired = isNaN(new Date(expiresAt).getTime()) ? true : new Date(expiresAt) < new Date()

    if (hasToken && isExpired) {
        try {
            const newToken = await NetworkAuth.refreshToken()
            localStorage.setItem('access_token', newToken)
            return true;
        } catch (err) {
            console.error(err)
            store.commit('auth/setUser', null)
            return {name: 'Login'}
        }
    } else if (!hasToken) {
        store.commit('auth/setUser', null)
        return {name: 'Login'}
    }
    return true
}

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {middlewares: [auth]},
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/Dashboard.vue')
    },
    {
        path: '/changeProfile',
        name: 'ChangeProfile',
        meta: {middlewares: [auth]},
        component: () => import(/* webpackChunkName: "about" */ '../views/ChangeProfile.vue')
    },
    {
        path: '/nfc/tags',
        name: 'NfcTags',
        meta: {middlewares: [auth]},
        component: () => import(/* webpackChunkName: "about" */ '../views/tag/NfcTags.vue')
    },
    {
        path: '/nfc/tags/edit',
        name: 'CreateNfcTags',
        meta: {middlewares: [auth]},
        component: () => import(/* webpackChunkName: "about" */ '../views/tag/EditNfcTag.vue')
    },
    {
        path: '/mediapool',
        name: 'MediaPool',
        meta: {middlewares: [auth]},
        component: () => import(/* webpackChunkName: "about" */ '../views/media/Mediapool.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../views/login/Login.vue')
    },
    {
        path: '/tag',
        name: 'Tag',
        component: () => import(/* webpackChunkName: "about" */ '../views/customer/CustomerView.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach(async (to, from, next) => {
    // set all nfc tag settings to default
    store.commit('content/removeLogo')
    store.commit("helper/setShowNavBar", true)
    store.commit("helper/setBackgroundColor", null)
    store.commit("helper/setBarColor", null)

    // middleware
    if (!to.meta?.middlewares) {
        next()
        return
    }
    for (const middleware of to.meta.middlewares) {
        const result = await middleware(to, from)
        if (typeof result === 'object' && result !== null) {
            next(result)
            return
        }
        if (!result) {
            next(false)
            return
        }
    }
    next()
})

export default router
