export default {
    namespaced: true,
    state: {
        nfcContents: [],
        logo: null,
    },
    mutations: {
        setLogo: (state, logo) => {
            state.logo = logo
        },
        removeLogo: (state) => {
            state.logo = null;
        },
        setContentsOneToOne: (state, contents) => {
            state.nfcContents = contents;
        },
        setContents: (state, contents) => {
            const rows = [];
            for (const content of contents) {
                const row = rows.find(row => row.index === content.order)
                if (!row) {
                    rows.push({
                        index: content.order,
                        items: [content]
                    })
                } else {
                    row.items.push(content)
                }
            }
            rows.sort((a, b) => {
                if (a.index === b.index) return 0;
                return a.index < b.index ? -1 : 1;
            })
            rows.forEach(row => {
                row.items.sort((a, b) => {
                    if (a.col === b.col) return 0;
                    return a.col < b.col ? -1 : 1;
                })
            })
            state.nfcContents = rows
        },
        addContent: (state, content) => {
            let nfcContents = state.nfcContents

            const row = nfcContents.find(row => row.index === content.order)
            if (!row) {
                nfcContents.push({
                    index: content.order,
                    items: [content]
                })
            } else {
                row.items.push(content)
            }
            nfcContents.sort((a, b) => {
                if (a.index === b.index) return 0;
                return a.index < b.index ? -1 : 1;
            })
            nfcContents.forEach(row => row.items.sort((a, b) => {
                if (a.col === b.col) return 0;
                return a.col < b.col ? -1 : 1;
            }))

            state.nfcContents = nfcContents
        },
        removeContent: (state, content) => {
            for (const nfcContent of state.nfcContents) {
                const index = nfcContent.items.indexOf(content)
                if (index >= 0) {
                    nfcContent.items.splice(index, 1)
                    break;
                }
            }
        },
        updateContent: (state, content) => {
            let positionToAdd = content.order
            let found = false;
            // remove old content and add new content
            for (const nfcContent of state.nfcContents) {
                for (let j = 0; j < nfcContent.items.length; j++) {
                    if (nfcContent.items[j].key === content.key) {
                        nfcContent.items.splice(j, 1, content)
                        found = true
                        break;
                    }
                }
            }
            if (found) {
                return;
            }

            // Check whether an other object is present at the specified position.
            // If so, move all objects back by one to make room for the insertion.
            if (content.col === 1) {
                for (const nfcContent of state.nfcContents) {
                    let contentWithSamePosition = nfcContent.items.find(content => content.order === positionToAdd)
                    if (contentWithSamePosition !== undefined && contentWithSamePosition.key !== content.key) {
                        for (let i = 0; i < state.nfcContents.length; i++) {
                            if (i >= positionToAdd) {
                                state.nfcContents[i].index = state.nfcContents[i].index + 1
                                for (const item of state.nfcContents[i].items) {
                                    item.order = item.order + 1
                                }
                            }
                        }
                    }
                }
            }
            const row = state.nfcContents.find(row => row.index === content.order)
            if (!row) {
                state.nfcContents.push({
                    index: content.order,
                    items: [content]
                })
            } else {
                row.items.push(content)
            }
            state.nfcContents.sort((a, b) => {
                if (a.index === b.index) return 0;
                return a.index < b.index ? -1 : 1;
            })
            state.nfcContents.forEach(row => row.items.sort((a, b) => {
                if (a.col === b.col) return 0;
                return a.col < b.col ? -1 : 1;
            }))
        }
    },
    actions: {},
    getters: {},
}