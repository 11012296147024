import axios from "axios";

export class NetworkAuth {

    static login(params, config) {
        return axios.post('/api/auth/login', params, config);
    }

    static logout(config) {
        return axios.post('/api/auth/logout', config);
    }

    static refreshToken(config) {
        return axios.post('/api/auth/refresh', config)
    }

    static getUserData(config) {
        return axios.post('api/auth/me', config)
    }
}