
export default class NetworkHelper {
    static buildHeaders(headers = {}) {
        return {
            ...this.authHeaders(),
            'X-localization': "de",
            ...headers
        }
    }

    static authHeaders() {
        const token = localStorage.getItem("access_token");
        if (token) {
            return {'Authorization': 'Bearer ' + token};
        } else {
            return {};
        }
    }
}