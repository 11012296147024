<template>
  <v-app :style="{background: backgroundColor !== null ? backgroundColor : $vuetify.theme.themes.light.background}">
    <link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet">
    <v-app-bar
        v-if="showAppBar"
        transition="fade"
        app
        :color="barColor !== null ? barColor : 'primary'"
        dark
        clipped-left>
      <v-app-bar-nav-icon v-if="showNavBar && $vuetify.breakpoint.mobile && user !== null"
                          @click="drawer = true"/>
      <v-row v-if="logo" no-gutters :justify="alignmentLogo">
        <img
            class="logo"
            :src="logo"
            alt="Logo"/>
      </v-row>

    </v-app-bar>

    <v-navigation-drawer
        app
        v-if="user !== null && showNavBar"
        v-model="drawer"
        class="max-height"
        left
        clipped
        :permanent="!$vuetify.breakpoint.mobile"
        :temporary="$vuetify.breakpoint.mobile">

      <v-list>
        <v-list-item link to="/changeProfile">
          <v-list-item-content>
            <v-list-item-title class="text-h6" v-text="user.name"/>
            <v-list-item-subtitle v-text="user.email"/>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider/>

      <v-list nav dense>
        <v-list-item v-for="(item, i) in items" :key="i" link :to="item.link">
          <v-list-item-icon>
            <v-icon v-text="item.icon"/>
          </v-list-item-icon>
          <v-list-item-title v-text="item.text"/>
        </v-list-item>
      </v-list>

      <v-divider/>

      <v-list nav dense>
        <v-list-item @click="logout" link>
          <v-list-item-icon>
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Abmelden</v-list-item-title>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
    <notification v-model="showAlert" :text="alertText" type="success"/>
  </v-app>
</template>

<script>

import Notification from "./components/Notification";


export default {
  name: 'App',
  components: {Notification},
  data() {
    return {
      drawer: false,
      items: [
        {text: 'Übersicht', icon: 'home', link: "/dashboard"},
        {text: 'NFC Tags', icon: 'nfc', link: "/nfc/tags"},
        {text: 'Media-Pool', icon: 'perm_media', link: "/mediapool"}
      ],
      alertText: "Erfolgreich abgemeldet",
      showAlert: false,
    }
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch('auth/logout')
        this.showAlert = true;
        await this.$router.push('/login')
      } catch (err) {
        return err
      }
    },
  },
  computed: {
    backgroundColor() {
      return this.$store.state.helper.backgroundColor
    },
    barColor() {
      return this.$store.state.helper.barColor
    },
    logo() {
      return this.$store.state.content.logo
    },
    user() {
      return this.$store.state.auth.user
    },
    alignmentLogo() {
      return this.$store.state.helper.alignmentLogo
    },
    /**
     * hide app bar, if user want to drag contents
     * With app bar, user can't scroll with content
     * @returns {*}
     */
    showAppBar() {
      return this.$store.state.helper.showAppBar
    },
    showNavBar() {
      return this.$store.state.helper.showNavBar
    }
  }
};
</script>
<style scoped>
.max-height {
  height: 100vh !important;
}

.logo {
  height: 50px;
  width: auto;
}

</style>

