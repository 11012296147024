import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        options: {customProperties: true},
        themes: {
            light: {
                primary: '#039be5',
                secondary: '#bdbdbd',
                accent: '#8bc34a',
                error: '#e64a19',
                warning: '#ffa000',
                info: '#0288d1',
                success: '#388e3c',
                background: '#f5f5f5',
                cardBackground: '#cfdce6',
            },
        },
    },
});
