import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import vuetify from './plugins/vuetify'
import NetworkHelper from "./helper/network/NetworkHelper";
import VueKonva from 'vue-konva'
import $ from 'jquery'

window.jQuery = $ //required for trumbowyg
window.$ = $

Vue.config.productionTip = false
Vue.use(VueKonva)

axios.defaults.baseURL = process.env.VUE_APP_API;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.interceptors.request.use(config => {
    config.headers = {
        ...NetworkHelper.authHeaders(),
        'X-localization': process.env.VUE_APP_I18N_LOCALE,
        ...config.headers,
    };

    return config;
});

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status !== 401) {
            return Promise.reject(error);
        }
        store.commit('auth/setUser', null)
        localStorage.setItem('access_token', null)
        if (router.currentRoute.name === "Login") {
            return Promise.reject(error);
        }
        router.push({name: "Login"})
        return Promise.reject(error);
    });

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
