import {NetworkAuth} from "../../../helper/network/auth/NetworkAuth";

function getExpiresAt(seconds) {
    const date = new Date();
    date.setSeconds(date.getSeconds() + seconds);
    return date.getTime()
}

export default {
    namespaced: true,
    state: {
        expiresAt: localStorage.getItem('expires_at') === 'null' ? null : localStorage.getItem('expires_at') || null,
        user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem('user')) : null,
    },
    mutations: {
        setUser: (state, user) => {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
        },
        setExpiresAt: (state, expiresAt) => {
            state.expiresAt = expiresAt
            localStorage.setItem('expires_at', expiresAt)
        },
    },
    actions: {
        async login({commit}, payload) {
            try {
                const {data} = await NetworkAuth.login(payload)
                const {access_token} = data
                const {expires_in} = data
                localStorage.setItem('access_token', access_token)
                commit('setExpiresAt', getExpiresAt(expires_in))
                const userData = await NetworkAuth.getUserData()
                const user = userData.data.data
                commit('setUser', user)
                return user
            } catch (err) {
                console.error(err)
                return err
            }
        },
        async logout(context) {
            try {
                await NetworkAuth.logout()
            } catch (err) {
                console.error(err)
                return err
            } finally {
                localStorage.setItem('access_token', null)
                context.commit('setExpiresAt', null)
                context.commit('setUser', null)
            }
        }
    },
    getters: {}
}
