import Vue from 'vue'
import Vuex from 'vuex'
import auth from "../store/modules/auth/auth"
import content from "./modules/content/content";
import helper from "./modules/helper/helper";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    content,
    helper
  }
})
