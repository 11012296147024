<template>
  <!--  types = success | info | warning | error-->
  <v-alert transition="fade" id="alert" :value="value" :type="type">
    {{ text }}
  </v-alert>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Boolean
    },
    type: {
      required: false,
      type: String,
      default: "success"
    },
    text: {
      required: true,
      type: String,
    },
  },
  computed: {
    showAlert() {
      return this.value
    }
  },
  watch: {
    showAlert() {
      setTimeout(() => {
        this.$emit('input', false)
      }, 3000)
    }
  }
}
</script>

<style scoped>
#alert {
  position: fixed;
  opacity: 0.95;
  top: 4px;
  right: 4px;
  z-index: 999;
}

</style>