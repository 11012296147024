export default {
    namespaced: true,
    state: {
        showAppBar: true,
        showNavBar: true,
        backgroundColor: null,
        barColor: null,
        alignmentLogo: 'start',
    },
    mutations: {
        setShowAppBar: (state, showAppBar) => {
            state.showAppBar = showAppBar
        },
        setShowNavBar: (state, showNavBar) => {
            state.showNavBar = showNavBar
        },
        setBackgroundColor: (state, color) => {
            state.backgroundColor = color
        },
        setBarColor: (state, color) => {
            state.barColor = color
        },
        setAlignmentLogo: (state, alignment) => {
            if (alignment === null) {
                state.alignmentLogo = "start"
                return
            }
            state.alignmentLogo = alignment
        }
    },
    actions: {},
    getters: {}
}